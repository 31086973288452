export const getPageNumbersToLink = (currentPage: number, totalPages: number): number[] => {
    if (typeof currentPage === 'string') {
        currentPage = parseInt(currentPage);
    }

    const maximumLinks = 5;

    if (totalPages <= maximumLinks) {
        return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    const leftSide = Math.floor(maximumLinks / 2);
    const rightSide = Math.ceil(maximumLinks / 2) - 1;

    if (currentPage <= leftSide) {
        return Array.from({ length: maximumLinks }, (_, i) => i + 1);
    }

    if ((currentPage + rightSide) >= totalPages) {
        return Array.from({ length: maximumLinks }, (_, i) => totalPages - maximumLinks + i + 1);
    }

    return Array.from({ length: maximumLinks }, (_, i) => currentPage - leftSide + i);
}
