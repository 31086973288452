import {container, secondaryColor, sidebarColor} from "../../../nextjs-material-kit.js";
import {cardTitle, title} from "../../../nextjs-material-kit.js";
import imagesStyles from "../../imagesStyles.js";

const carouselStyle = (theme) => ({
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  floatLeft: {
    float: "left",
  },
  iconContent: {
    alignItems: 'center',
  },
  floatRight: {
    float: "right",
  },
  jobPic: {
    border: "1px solid " + secondaryColor,
    maxWidth: "268px !important",
    marginLeft: "40px !important",
    marginTop: "70px",
    marginBottom: "-20px",
  },
  primaryColor: {
    backgroundColor: "#095a70",
    padding: "5px 15px !important",
    marginRight: "15px",
  },
  lightBlue: {
    backgroundColor: "#7db2e1",
  },
  jobTab: {
    paddingBottom: "0px",
    paddingTop: "0px",
    "@media (max-width: 768px)": {
      padding: 0,
    },
  },
  ...imagesStyles,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: "0",
    padding: "0px",
    border: "1px solid " + secondaryColor,
    borderBottom: "none",
  },
  cardTitle: {
    ...cardTitle,
    color: sidebarColor,
    fontFamily: "var(--font-brandon-grotesque)",
    fontWeight: 900,
    fontSize: "19px",
    letterSpacing: "-0.5px",
    marginBottom: "2px",
    marginTop: "4px !important",

    '.course &': {
      color: secondaryColor,
    },
  },
  smallTitle: {
    fontFamily: "var(--font-brandon-grotesque)",
    color: "#000",
    fontSize: "16px",
    letterSpacing: "-0.5px",
    marginBottom: 0,
    lineHeight: "1.25",
  },
  description: {
    color: "#999",
  },
  justifyCenter: {
    justifyContent: "center !important",
    paddingBottom: "0 !important",
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999",
  },
  margin5: {
    fontSize: "9px",
    textTransform: "capitalize",
    textAlign: "left",
    marginLeft: "20px",
    textIndent: "-5px",
    minWidth: "75px",
  },
  //Carousel Styles
  centerDiv: {
    margin: "0px auto 0 !important",
    width: "165px",
    border: "0px solid #73AD21",
    padding: "0",
  },
  leftAlign: {
    padding: "15px 10px 15px",
    backgroundColor: "#eaf6fe",
  },
  jobIcons: {
    width: "17px",
    margin: "0",
    color: sidebarColor,
    fontSize: '1em',
    lineHeight: '0',
    cursor: 'pointer',
    svg: {
      fill: sidebarColor,
    },
    '.course & svg': {
      fill: secondaryColor,
    },
  },
  locationIcon: {
    "@media (max-width: 768px)": {
      marginBottom: "10px !important",
    },
  },
  side_Icon: {
    width: "13px !important",
    height: "13px !important",
  },
  side_Icon2: {
    height: "12px !important",
  },
  small: {
    padding: "0px",
    marginBottom: "0px",
    marginTop: "0px",
    float: "left",
    lineHeight: "1.5",
    fontFamily: "var(--font-brandon-grotesque)",
    fontSize: "13px",
    fontWeight: "100",
    letterSpacing: 0,
    marginLeft: "8px",
  },
  section: {
    padding: "30px 0 22px",
  },
  jobCard: {
    paddingLeft: "0px",
    paddingRight: "0px",
    marginTop: "0",
    boxShadow: "0 0 0 1px " + secondaryColor,
    borderRadius: "0",
    marginBottom: "17px",

    '&.course': {
      boxShadow: "0 0 0 1px " + sidebarColor,
    },
  },
  container: {
    ...container(theme),
  },
  centred: {
    textAlign: "center",
    width: "auto",
  },
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
  },
  inactive: {
    backgroundColor: "#7DB2E1",
  },
  stdBtn: {
    borderRadius: "0 !important",
    fontWeight: "400 !important",
    fontFamily: "var(--font-brandon-grotesque) !important",
    textTransform: "inherit !important",
    fontSize: "16px !important",
    paddingTop: "8px",
    paddingBottom: "8px",
    boxShadow: "none",
  },
  sideIcons: {
    textAlign: "right",
    paddingRight: "20px",
  },
  bgWhite: {
    background: "#FFFFFF",
  },
  pageInfo: {
    fontSize: "80%",
  },
  cardContent: {
    padding: '0 20px',
  }
});

export default carouselStyle;
