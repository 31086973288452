import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { makeStyles } from 'tss-react/mui';
import Button from "@mui/material/Button";

import styles from "../../styles/jss/nextjs-material-kit/components/paginationStyle.js";
import Link from "next/link";

const useStyles = makeStyles()(styles);

export default function Pagination(props) {
  const { classes, cx } = useStyles();
  const { pages, color } = props;
  return (
    <ul className={classes.pagination}>
      {pages.map((prop, key) => {
        const paginationLink = cx({
          [classes.paginationLink]: true,
          [classes[color]]: prop.active,
          [classes.disabled]: prop.disabled,
        });
        return (
          <li className={classes.paginationItem} key={key}>
            <Link href={prop.url}>
              <Button className={paginationLink}>
                {prop.text}
              </Button>
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

Pagination.defaultProps = {
  color: "primary",
};

Pagination.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      disabled: PropTypes.bool,
      text: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf(["PREV", "NEXT", "..."]),
      ]).isRequired,
      onClick: PropTypes.func,
    })
  ).isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
};
