import {
  hexToRGBAlpha,
  grayColor,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor,
} from "../../nextjs-material-kit.js";

const paginationStyle = {
  pagination: {
    display: "inline-block",
    paddingLeft: "0",
    margin: "0 auto 20px auto",
  },
  paginationItem: {
    display: "inline",
  },
  paginationLink: {
    borderRadius: "0 !important",
    fontFamily: "var(--font-brandon-grotesque)",
    fontSize: "16px",
    padding: "5px 15px",
    margin: "5px 15px 5px 1px",
    boxShadow: "none",
    ":first-of-type": {
      marginleft: "0",
    },
    border: "0",
    transition: "all .3s",
    minWidth: "30px",
    height: "30px",
    lineHeight: "30px",
    minHeight: "auto",
    background: "transparent",
    position: "relative",
    float: "left",
    textDecoration: "none",
    "&,&:hover,&:focus": {
      color: grayColor,
    },
    "&:hover,&:focus": {
      zIndex: "3",
      backgroundColor: "#eee",
      borderColor: "#ddd",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
  primary: {
    "&,&:hover,&:focus": {
      backgroundColor: primaryColor,
      borderColor: primaryColor,
      color: "#FFFFFF",
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default",
    },
  },
  info: {
    "&,&:hover,&:focus": {
      backgroundColor: infoColor,
      borderColor: infoColor,
      color: "#FFFFFF",
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default",
    },
  },
  success: {
    "&,&:hover,&:focus": {
      backgroundColor: successColor,
      borderColor: successColor,
      color: "#FFFFFF",
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default",
    },
  },
  warning: {
    "&,&:hover,&:focus": {
      backgroundColor: warningColor,
      borderColor: warningColor,
      color: "#FFFFFF",
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default",
    },
  },
  danger: {
    "&,&:hover,&:focus": {
      backgroundColor: dangerColor,
      borderColor: dangerColor,
      color: "#FFFFFF",
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default",
    },
  },
  rose: {
    "&,&:hover,&:focus": {
      backgroundColor: roseColor,
      borderColor: roseColor,
      color: "#FFFFFF",
    },
    "&:hover,&:focus": {
      zIndex: "2",
      cursor: "default",
    },
  },
  disabled: {
    "&,&:hover,&:focus": {
      color: "#777",
      cursor: "not-allowed",
      backgroundColor: "#fff",
      borderColor: "#ddd",
    },
  },
};

export default paginationStyle;
